import moment from "moment";
const now = moment().hour(14).minute(30);

const DELIVERIES_3_HOURS_TIME_ARRAY = [
  { value: "09:00 - 12:00", option: "09:00 - 12:00" },
  { value: "10:00 - 13:00", option: "10:00 - 13:00" },
  { value: "11:00 - 14:00", option: "11:00 - 14:00" },
  { value: "12:00 - 15:00", option: "12:00 - 15:00" },
  { value: "13:00 - 16:00", option: "13:00 - 16:00" },
  { value: "14:00 - 17:00", option: "14:00 - 17:00" },
  { value: "15:00 - 18:00", option: "15:00 - 18:00" },
  { value: "16:00 - 19:00", option: "16:00 - 19:00" },
  { value: "17:00 - 20:00", option: "17:00 - 20:00" },
  { value: "18:00 - 21:00", option: "18:00 - 21:00" },
  { value: "19:00 - 22:00", option: "19:00 - 22:00" },
];
const DELIVERIES_1_HOURS_TIME_ARRAY = [
  { value: "09:00 - 10:00", option: "09:00 - 10:00" },
  { value: "10:00 - 11:00", option: "10:00 - 11:00" },
  { value: "11:00 - 12:00", option: "11:00 - 12:00" },
  { value: "12:00 - 13:00", option: "12:00 - 13:00" },
  { value: "13:00 - 14:00", option: "13:00 - 14:00" },
  { value: "14:00 - 15:00", option: "14:00 - 15:00" },
  { value: "15:00 - 16:00", option: "15:00 - 16:00" },
  { value: "17:00 - 18:00", option: "17:00 - 18:00" },
  { value: "18:00 - 19:00", option: "18:00 - 19:00" },
  { value: "19:00 - 20:00", option: "19:00 - 20:00" },
  { value: "20:00 - 21:00", option: "20:00 - 21:00" },
];
const DELIVERIES_TIME_ARRAY = [
  { value: 600, option: "21:00 - 00:00" },
  { value: 600, option: "21:00 - 22:00" },
  { value: 1000, option: "22:00 - 23:00" },
  { value: 1000, option: "23:00 - 00:00" },
  { value: 1200, option: "23:00" },
  { value: 1200, option: "00:00" },
];
const DELIVERIES = [
  {
    value: 0,
    label: "Доставка в 3-х часовой интервал - 490 ₽",
    price: 490,
  },
  { value: 1, label: "Доставка в часовой интервал - 790 ₽", price: 790 },
  { value: 2, label: "Доставка к точному времени - 1000 ₽", price: 1000 },
  { value: 4, label: "Дополнительные интервалы", price: 0 },
];

const SELF_DELIVERY_PLACES = [
  { value: 0, label: "Москва, Семёновский пер., 6" },
   /* { value: 1, label: "Москва, ул. Садовая-Спасская улица, д. 12/23с2" },*/
  { value: 2, label: "Москва, ул. Серафимовича, д.2" },
  /* { value: 2, label: "Москва, пр. Вернадского 11/19" }, */
];



export default {
 

  DEFAULT_ORDER_STATE: {

    loader: false,
    showTopBtn: false,
    scrollHeader: false,
    basketNum: 0,
    appliedBonuses: 0,

    user: {
      name: "",
    },

    modalCatalogActive: false,
    modalDeliveryActive: false,
    modalLoginActive: false,
    modalRulesActive: false,
    
    minimalPrice: 0,

    deliveryType: 0,
    selectDeliveryTime: "09:00 - 12:00",
    addressEntrance: "",
    addressHome: "",
    addressRoom: "",
    addressFloor: "",
    selectedDeliveryTimeValue: null,
    selectDeliveryExactTime: now,
    selectDeliveryExactDate: new Date(),
    selectDeliveryTimeValue: 0,
    selfDeliveryPlaceValue: 0,
    selfDeliveryPlaces:  SELF_DELIVERY_PLACES,
    excludeDates: [new Date(2022, 0, 1), new Date(2022, 0, 2)],
    deliveriesTimeArrayDefault: [],
    deliveries1HoursTimeArrayDefault: [],
    deliveries3HoursTimeArrayDefault: [],
    deliveries3HoursTimeArray: DELIVERIES_3_HOURS_TIME_ARRAY,
    deliveries1HoursTimeArray: DELIVERIES_1_HOURS_TIME_ARRAY,
    deliveriesTimeArray: DELIVERIES_TIME_ARRAY,
    deliveries: DELIVERIES,
    deliverySettings: [],

    deliveryData: null,
    cart: [],
    giftsList: [],
    addressesHistory: [],
    settings: {
      settings_price_giftbox: 300,
      settings_sale: 0,
      settings_percent_to_bonus: 0,
      settings_max_percent_discount: 15,
    },
    totalDiscount: 0,
    selectedDeliveryTime: "",
    selectedDeliveryDateValue: new Date(),
    selectedDeliveryPrice: 0,
    selectedDeliveryValue: 0,
    paymentType: 1,

    additionalSettings: {
      iReceiver: false,
      receiverSettings: {
        name: null,
        phone: null,
      },
      callBeforeDelivery: false,
      makePhoto: true,
      differentBoxes: true,
      image: null,
      boxCount: 1,
    },

    deliveryActive: 0,
    deliveryType: 0,
    addressesType: "new",
    addressString: "",
    addressDistance: 0,

    postCardText: "",
    commentText: "",

    errorsMsgList: ["Доставка не выбрана", "Поле адрес не заполнено"],

    selectedDate: new Date(),
    selectedTime: "9:00 - 12:00",
  }
};
