import React, { Component } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import TimePicker from "rc-time-picker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import { Icon } from "@iconify/react";
import alertCircle from "@iconify/icons-zmdi/alert-circle";
import plusIcon from "@iconify/icons-zmdi/plus";
import minusIcon from "@iconify/icons-zmdi/minus";

import "./Order.scss";
import "rc-time-picker/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";

import UiBtnLight from "../../components/buttons/UiBtnLight";
import UiBtnPink from "../../components/buttons/UiBtnPink";
import UiBtnPinkOutline from "../../components/buttons/UiBtnPinkOutline";
import UiBtnTop from "../../components/buttons/UiBtnTop";
import UiCheckList from "../../components/forms/UiCheckList";
import UiHeader from "../../components/header/UiHeader";
import UiHistoryCard from "../../components/cards/UiHistoryCard";
import UiFooter from "../../components/footer/UiFooter";
import UiLoader from "../../components/modals/UiLoader";
import UiModalDelivery from "../../components/modals/UiModalDelivery";
import UiModalLogin from "../../components/modals/UiModalLogin";
import UiModalAlert from "../../components/modals/UiModalAlert";
import UiModalPayment from "../../components/modals/UiModalPayment";
import UiModalRules from "../../components/modals/UiModalRules";
import UiCheckbox from "../../components/forms/UiCheckbox";
import UiSelect from "../../components/forms/UiSelect";
import UiSwitch from "../../components/forms/UiSwitch";
import UiTextArea from "../../components/forms/UiTextArea";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextInputMasked from "../../components/forms/UiTextInputMasked";
import UiTextInputSV from "../../components/forms/UiTextInputSV";
import UiMapAddress from "../../components/map/UiMapAddress";

import { Helmet } from "react-helmet";

import {
  formatDate,
  formatDateYMD,
  compareDate,
} from "../../components/common/Dates";
import OrderConstant from "../../constants/OrderConstant";

import { appendScript } from "../../components/common/Importer";
import { loadUser, logout } from "../../services/Auth";
import { storeData, retrieveData, addFile } from "../../services/Storage";
import {
  createOrder,
  createOrderOnline,
  updateUser,
} from "../../services/Users";

import {
  getSettings,
  getInterval,
  getMinimalPrice,
  getDeliveryPrices,
} from "../../services/Settings";
import { GetFlowersByCategory, GetFlowersById } from "../../services/Flowers";
import { getEnvOfStorage } from "../../services/EnvContext";
import {
  CART,
  clearOrderId,
  generateOrderIdForCart,
  getBalance,
  setOrder,
  updateClient,
} from "../../services/Loyalty";
import {
  setAppliedBonuses,
  setTotalBonuses,
  setTotalDiscount,
} from "../../Store/Slices/LoyaltySlice";
import { connect } from "react-redux";
import UiAvailableBonuses from "../../components/loyalty/UiAvailableBonuses";
import UiDeliverPriceCaption from "../../components/delivery/UIDeliverPriceCaption";

const now = moment().hour(14).minute(30);

class OrderPage extends Component {
  state = OrderConstant.DEFAULT_ORDER_STATE;

  constructor(props) {
    super(props);
    registerLocale("ru", ru);
  }

  componentDidMount() {
    this._load();
  }

  async _loadInterval(_d) {
    this.setState({ loader: true, disableDeliveryToExactTime: false });
  
    //минимальная цена в день доставки
    getMinimalPrice(formatDateYMD(_d), "all").then((res) => {
      if (res.response) {
        if (res.response.enable) {
          this.setState({ minimalPrice: res.response.price });
        } else {
          this.setState({ minimalPrice: 0 });
        }
      } else {
        this.setState({ minimalPrice: 0 });
      }
    });

    return getInterval(formatDateYMD(_d), "all").then((res) => {
      if (res.response) {
        let _deliveries3HoursTimeArray = [];
        let _deliveries1HoursTimeArray = [];
        let _deliveriesTimeArray = [];
        res.response.map((item) => {
          if (item.enable) {
            if (item.type == 0)
              _deliveriesTimeArray.push({
                value: item.value,
                option: item.date,
              });
            if (item.type == 1)
              _deliveries1HoursTimeArray.push({
                value: item.date,
                option: item.date,
              });
            if (item.type == 2)
              _deliveries3HoursTimeArray.push({
                value: item.date,
                option: item.date,
              });
          }
          if (item.type == 3 && !item.enable)
            this.setState({ disableDeliveryToExactTime: true });
        });
        return this.setState(
          {
            deliveriesTimeArray: _deliveriesTimeArray,
            deliveriesTimeArrayDefault: _deliveriesTimeArray,
            deliveries1HoursTimeArrayDefault: _deliveries1HoursTimeArray,
            deliveries3HoursTimeArrayDefault: _deliveries3HoursTimeArray,
            loader: false,
          },
          () => {
            return true;
          }
        );
      }
    });
  }

  _excludeSpecialInterval(_list) {
    let arr = [];
    _list.map((item) => {
      if (item.value != 2) {
        arr.push(item);
      } else {
        if (item.value == 2 && !this.state.disableDeliveryToExactTime)
          arr.push(item);
      }
    });
    return arr;
  }

  _load() {
    appendScript("https://widget.cloudpayments.ru/bundles/cloudpayments");
    window.ym(55588969, "hit", "/order", {});
    let _cart = retrieveData("cart");
    generateOrderIdForCart();
    let _user = loadUser();
    if (_cart) {
      this.setState({ cart: _cart });
      this.setState({ basketNum: _cart.length });
    } else {
      this.props.history.push("/");
    }
    if (_user) {
      this.setState({ user: _user, userName: _user.name });
    } else {
      logout();
      this.props.history.push("/");
    }

    getSettings().then((res) => {
      if (res.response) this.setState({ settings: res.response });
    });

    getDeliveryPrices().then((resDeliveryPrices) => {
      if (resDeliveryPrices.response) {
        this.setState({ deliverySettings: resDeliveryPrices.response });

        //storeData("mfUserHistory", null);
        let _addressHistory = retrieveData("mfUserHistory");
        if (_addressHistory == null) _addressHistory = [];
        _addressHistory.push({
          value: _addressHistory.length,
          label: "Добавить новый адрес",
          distance: 0,
          type: "new",
        });
        _addressHistory.push({
          value: _addressHistory.length,
          label: "Уточнить адрес у получателя",
          distance: 0,
          type: "call",
        });
        if (_addressHistory.length > 1) {
          if (
            _addressHistory[0].type == "old" &&
            this.state.addressString == ""
          ) {
            this.setState({ addressesType: _addressHistory[0].type });
            this._changeAddress(
              {
                address: _addressHistory[0].label,
                distance: _addressHistory[0].distance,
              },
              resDeliveryPrices.response
            );
          }
        }
        this.setState({ addressesHistory: _addressHistory });
      }
    });

    this._loadInterval(this.state.selectDeliveryExactDate).then(() => {
      this._checkAvailableDate(this.state.selectDeliveryExactDate).then(() => {
        if (this.state.deliveries3HoursTimeArray.length > 0)
          this.setState({
            selectDeliveryTime: this.state.deliveries3HoursTimeArray[0].value,
            selectDeliveryTimeValue:
              this.state.deliveries3HoursTimeArray[0].value,
          });
      });
    });

    window.addEventListener("scroll", () => {
      var limit = window.innerHeight;
      let scrollY = window.scrollY;
      if (document.getElementById("basket-list")) {
        let pageYOffset = document.getElementById("basket-list").offsetTop;
        if (scrollY < 233) pageYOffset = 230 - scrollY;
        else pageYOffset = 8;

        document.getElementById("basket-list").style.top = pageYOffset + "px";
      }
    });

    // Пересчитать начисленные бонусы с учетом имеющегося товара
    this.recalculateBonuses(_cart, _user);
  }

  /**
   * Пересчитать начисленные бонусы с учетом имеющегося товара
   * */
  recalculateBonuses(cart, user) {
    getBalance(user)
      .then((data) => {
        if (data?.client) {
          this.props.setTotalBonuses(data?.client?.bonuses | 0);
        }
      })
      .catch((err) => console.error(err));
  }

  _checkAvailableDate = async (_date) => {
    let _currentDateNow = new Date();
    if (_date > _currentDateNow) {
      return this.setState(
        {
          deliveries3HoursTimeArray:
            this.state.deliveries3HoursTimeArrayDefault,
          deliveries1HoursTimeArray:
            this.state.deliveries1HoursTimeArrayDefault,
          selectDeliveryTime:
            this.state.deliveries3HoursTimeArrayDefault.length > 0
              ? this.state.deliveries3HoursTimeArrayDefault[0].value
              : "",
          selectedDeliveryTimeValue:
            this.state.deliveries3HoursTimeArrayDefault.length > 0
              ? this.state.deliveries3HoursTimeArrayDefault[0].value
              : null,
        },
        () => {
          return null;
        }
      );
    } else {
      if (_date) {
        let _currentData = _date;
        let _deliveries3HoursTimeArray = JSON.parse(
          JSON.stringify(this.state.deliveries3HoursTimeArrayDefault)
        );
        let _deliveries1HoursTimeArray = JSON.parse(
          JSON.stringify(this.state.deliveries1HoursTimeArrayDefault)
        );

        let _index = _deliveries3HoursTimeArray.length - 1;
        let _index2 = _deliveries1HoursTimeArray.length - 1;

        _deliveries3HoursTimeArray.map((item, index) => {
          let time1 = item.value.split(" - ")[0];
          let time2 = item.value.split(" - ")[1];
          if (
            Math.round(time1.split(":")[0]) <= _currentData.getHours() &&
            _currentData.getHours() <= Math.round(time2.split(":")[0])
          ) {
            _index = index;
          }
        });

        _deliveries1HoursTimeArray.map((item, index) => {
          let time1 = item.value.split(" - ")[0];
          let time2 = item.value.split(" - ")[1];
          if (
            Math.round(time1.split(":")[0]) <= _currentData.getHours() &&
            _currentData.getHours() <= Math.round(time2.split(":")[0])
          ) {
            _index2 = index;
          }
        });

        if (_deliveries3HoursTimeArray.length > 0) {
          if (
            Math.round(
              _deliveries3HoursTimeArray[0].value.split(" - ")[0].split(":")[0]
            ) > _currentData.getHours()
          ) {
            _index = -1;
          }
        }
        if (_deliveries1HoursTimeArray.length > 0) {
          if (
            Math.round(
              _deliveries1HoursTimeArray[0].value.split(" - ")[0].split(":")[0]
            ) > _currentData.getHours()
          ) {
            _index2 = -1;
          }
        }

        //Решение: если пользователь совершает заказ утром до 11ч., то ближайший доступный интервал доставки для него должен быть 11-12ч.
        if (
          _currentData.getHours() < 11 &&
          compareDate(_date, _currentDateNow)
        ) {
          _index2 = 1;
          _index = 2;
        }
        //Решение: закрыть все интервалы доставки при заказе после 19.30 на текущий день.
        let newArr2 = _deliveries1HoursTimeArray;
        let newArr = _deliveries3HoursTimeArray;
        if (
          _currentData.getHours() > 19 &&
          compareDate(_date, _currentDateNow)
        ) {
          newArr2 = [];
          newArr = [];
        } else {
          newArr2.splice(0, _index2 + 1);
          newArr.splice(0, _index + 1);

          this.setState({
            selectedDeliveryTimeValue: null,
            selectDeliveryExactTime: null,
          });
        }

        return this.setState(
          {
            deliveries3HoursTimeArray: newArr,
            deliveries1HoursTimeArray: newArr2,
            selectDeliveryTime: newArr.length > 0 ? newArr[0].value : null,
            selectedDeliveryTimeValue:
              newArr.length > 0 ? newArr[0].value : null,
          },
          () => {
            return null;
          }
        );
      }
    }
  };

  _disableEveningTimes(_time = null) {
    //Решение: закрыть все интервалы доставки при заказе после 19.30 на текущий день.
    //.format("HH:mm")
    return (
      this.state.selectDeliveryExactDate.getHours() > 19 &&
      compareDate(this.state.selectDeliveryExactDate, new Date())
    );
  }

  _change(_key, _index, _val) {
    let arr = this.state.cart;
    arr[_index][_key] = _val;
    this.setState({ cart: arr });
    storeData("cart", arr);
  }

  _selectHistoryAddress(value) {
    this.setState({
      adressTypeValue: value,
      addressesType: this.state.addressesHistory[value].type,
    });
    if (this.state.addressesHistory[value].type == "call") {
      this.setState({
        deliveries: [
          {
            value: 3,
            label: "Доставка в 3-х часовой интервал - 490 ₽",
            price: 490,
          },
        ],
        deliveryType: 3,
        selectedDeliveryPrice: 490,
        isShowIntervalForm: true,
      });
    } else if (this.state.addressesHistory[value].type == "old") {
      this._changeAddress(
        {
          address: this.state.addressesHistory[value].label,
          distance: this.state.addressesHistory[value].distance,
        },
        this.state.deliverySettings
      );
    } else {
      this.setState({ addressString: "", addressDistance: 0, deliveryType: 0 });
    }
  }

  _addAddressToHistory(_string, _distance) {
    if (_string) {
      let oldList = this.state.addressesHistory;
      let newList = [];
      let isNew = true;
      oldList.map((item) => {
        if (item.label == _string) isNew = false;
      });
      if (isNew) {
        newList[0] = {
          value: 0,
          label: _string,
          distance: _distance,
          type: "old",
        };
      }

      storeData("mfUserHistory", newList);
      newList.push({
        value: newList.length,
        label: "Добавить новый адрес",
        distance: 0,
        type: "new",
      });
      newList.push({
        value: newList.length,
        label: "Уточнить адрес у получателя",
        distance: 0,
        type: "call",
      });
      this.setState({ addressesHistory: newList });
    }
  }

  _changeAddress(res, _settings) {
    if (
      this.state.addressString != res.address &&
      this.state.addressString != "" &&
      this.state.addressHome != res.address &&
      this.state.addressHome != ""
    )
      this._alert(
        "Вы изменили адрес доставки. Просим заново выбрать интервал."
      );

    this.setState({
      addressString: res.address,
      addressDistance: res.distance,
      isShowIntervalForm: false,
    });

    if (res.distance == 0) {
      let deliveryList = [];
      if (_settings[1])
        if (_settings[1].is_active == 1)
          deliveryList.push({
            value: 0,
            label: `Доставка в 3-х часовой интервал - ${_settings[1].price} ₽`,
            price: _settings[1].price,
          });
      if (_settings[0])
        if (_settings[0].is_active == 1)
          deliveryList.push({
            value: 1,
            label: `Доставка в часовой интервал - ${_settings[0].price} ₽`,
            price: _settings[0].price,
          });
      if (_settings[7])
        if (_settings[7].is_active == 1)
          deliveryList.push({
            value: 2,
            label: `Доставка к точному времени - ${_settings[7].price} ₽`,
            price: _settings[7].price,
          });
      deliveryList.push({
        value: 4,
        label: "Дополнительные интервалы",
        price: 0,
      });

      if (_settings[1]) {
        this.setState(
          {
            deliveries: deliveryList,
            selectedDeliveryPrice: _settings[1].price,
            deliveryType: 0,
          },
          () => {
            this.setState({ isShowIntervalForm: true });
          }
        );
      }

      this._checkAvailableDate(this.state.selectDeliveryExactDate).then(() => {
        if (this.state.deliveries3HoursTimeArray.length > 0)
          this.setState({
            selectDeliveryTime: this.state.deliveries3HoursTimeArray[0].value,
            selectDeliveryTimeValue:
              this.state.deliveries3HoursTimeArray[0].value,
          });
      });
    } else if (
      res.distance > 0 &&
      res.distance <= 5 &&
      _settings[2]?.is_active == 1
    ) {
      this.setState(
        {
          deliveries: [
            {
              value: 0,
              label: `Доставка в 3-х часовой интервал - ${_settings[2].price} ₽`,
              price: _settings[2].price,
            },
          ],
          selectedDeliveryPrice: _settings[2].price,
        },
        () => {
          this.setState({ isShowIntervalForm: true });
        }
      );
    } else if (
      res.distance > 5 &&
      res.distance <= 10 &&
      _settings[3]?.is_active == 1
    ) {
      this.setState(
        {
          deliveries: [
            {
              value: 0,
              label: `Доставка в 3-х часовой интервал - ${_settings[3].price} ₽`,
              price: _settings[3].price,
            },
          ],
          selectedDeliveryPrice: _settings[3].price,
        },
        () => {
          this.setState({ isShowIntervalForm: true });
        }
      );
    } else if (
      res.distance > 10 &&
      res.distance < 15 &&
      _settings[4]?.is_active == 1
    ) {
      this.setState(
        {
          deliveries: [
            {
              value: 0,
              label: `Доставка в 3-х часовой интервал - ${_settings[4].price} ₽`,
              price: _settings[4].price,
            },
          ],
          selectedDeliveryPrice: _settings[4].price,
        },
        () => {
          this.setState({ isShowIntervalForm: true });
        }
      );
    } else if (
      res.distance > 15 &&
      res.distance < 20 &&
      _settings[5].is_active == 1
    ) {
      this.setState(
        {
          deliveries: [
            {
              value: 0,
              label: `Доставка в 3-х часовой интервал - ${
                _settings[5].price +
                Math.round(res.distance * _settings[5].distance_price)
              } ₽`,
              price:
                _settings[5].price +
                Math.round(res.distance * _settings[5].distance_price),
            },
          ],
          selectedDeliveryPrice:
            _settings[5].price +
            Math.round(res.distance * _settings[5].distance_price),
        },
        () => {
          this.setState({ isShowIntervalForm: true });
        }
      );
    } else if (res.distance > 19 && _settings[6].is_active == 1) {
      this.setState(
        {
          deliveries: [
            {
              value: 0,
              label: `Доставка в 3-х часовой интервал - ${
                _settings[6].price +
                Math.round(res.distance * _settings[6].distance_price)
              } ₽`,
              price:
                _settings[6].price +
                Math.round(res.distance * _settings[6].distance_price),
            },
          ],
          selectedDeliveryPrice:
            _settings[6].price +
            Math.round(res.distance * _settings[6].distance_price),
        },
        () => {
          this.setState({ isShowIntervalForm: true });
        }
      );
    }
  }

  _changeSettings(_property, _value) {
    console.log(_value);
    let _settings = this.state.additionalSettings;
    // if (_value == 1) _value = true;
    // if (_value == 0) _value = false;
    _settings[_property] = _value;
    this.setState({ additionalSettings: _settings });
  }

  onBonusChange(val) {
    let maxSumm =
      this._getTotalBasketSales() *
      ((this.state.settings?.settings_max_percent_discount | 15) / 100);
    let resSum = maxSumm < val ? maxSumm : val;
    this.setState({ appliedBonuses: resSum });
    this.props.setAppliedBonuses(resSum);
  }

  _getTotalBasket(excludeSale = false) {
    if (this.state.settings) {
      let arr = this.state.cart;
      let price = 0;
      let giftPrice = 0;
      let maxDiscount =
        (this.state.settings?.settings_max_percent_discount | 15) / 100;
      arr.map((item, index) => {
        //скидок на подарок нет
        if (item.category == 0 || item.category == 8 || item.category == 9) {
          giftPrice =
            giftPrice +
            parseFloat(item.price * item.count) +
            parseFloat(
              item.giftbox == 1 ? this.state.settings.settings_price_giftbox : 0
            );
        } else {
          price =
            price +
            parseFloat(item.price * item.count) +
            parseFloat(
              item.giftbox == 1 ? this.state.settings.settings_price_giftbox : 0
            );
        }
      });
      if (!excludeSale) {
        //Пересчитает стоимость заказа с учетом бонусов и скидки
        if (
          price +
            parseFloat(this.state.selectedDeliveryPrice) -
            this.state.appliedBonuses -
            this.props.totalDiscount >
          0
        ) {
          return (
            (price - this.props.totalDiscount < 0
              ? 0
              : price - this.props.totalDiscount) +
            giftPrice +
            parseFloat(this.state.selectedDeliveryPrice) -
            this.state.appliedBonuses
          );
        }
      }

      return price + giftPrice + parseFloat(this.state.selectedDeliveryPrice);
    }
  }

  _getTotalBasketSales() {
    if (this.state.settings) {
      let arr = this.state.cart;
      let giftPrice = 0;
      arr.map((item, index) => {
        //скидок на подарок нет
        if (item.category == 0 || item.category == 8 || item.category == 9) {
          giftPrice =
            giftPrice +
            parseFloat(item.price * item.count) +
            parseFloat(
              item.giftbox == 1 ? this.state.settings.settings_price_giftbox : 0
            );
        }
      });

      return giftPrice;
    }
  }

  _getPrice(_key) {
    let p = 0;
    this.state.deliveries.map((item) => {
      if (item.value == _key) p = item.price;
    });
    return p;
  }

  _setDelivery() {
    if (this.state.deliveryActive == 0) {
      if (this.state.deliveryType == 2) {
        this.setState({
          selectedDeliveryPrice: this._getPrice(this.state.deliveryType),
          selectedDeliveryDateValue: this.state.selectDeliveryExactDate,
          selectedDeliveryTimeValue: this.state.selectDeliveryExactTime
            ? this.state.selectDeliveryExactTime.format("HH:mm")
            : null,
        });
      } else if (this.state.deliveryType == 0) {
        this.setState({
          selectedDeliveryPrice: this._getPrice(this.state.deliveryType),
          selectedDeliveryDateValue: this.state.selectDeliveryExactDate,
          selectedDeliveryTimeValue: this.state.selectDeliveryTime,
        });
      } else if (this.state.deliveryType == 1) {
        this.setState({
          selectedDeliveryPrice: this._getPrice(this.state.deliveryType),
          selectedDeliveryDateValue: this.state.selectDeliveryExactDate,
          selectedDeliveryTimeValue: this.state.selectDeliveryTime,
        });
      } else if (this.state.deliveryType == 3) {
        this.setState({
          selectedDeliveryDateValue: this.state.selectDeliveryExactDate,
          selectedDeliveryTimeValue: null,
        });
      } else if (this.state.deliveryType == 4) {
        this.setState({
          selectedDeliveryDateValue: this.state.selectDeliveryExactDate,
          selectedDeliveryTimeValue: this.state.selectDeliveryTime,
        });
      }
    } else {
      this.setState({
        selectedDeliveryDateValue: this.state.selectDeliveryExactDate,
        selectedDeliveryTimeValue: this.state.selectDeliveryExactTime
          ? this.state.selectDeliveryExactTime.format("HH:mm")
          : null,
      });
    }
  }

  _trimInfoFromList(_list) {
    let arr = [];
    _list.map((item, index) => {
      arr.push({
        id: item.id,
        title: item.title,
        count: item.count,
        price: item.price,
        xml_id: item.xml_id,
      });
    });
    return arr;
  }

  _trimInfoFromListBX(_list) {
    let arr = [];
    _list.map((item, index) => {
      arr.push(
        item.title +
          " X " +
          item.count +
          (item.giftbox == 1 ? " завернуть в подарочную упаковку " : "") +
          " шт. -" +
          item.price +
          " р."
      );
    });
    return arr;
  }

  _trimInfoCheck(_list) {
    let arr = [];
    _list.map((item, index) => {
      arr.push({
        label: item.title,
        price: item.price,
        quantity: item.count,
        amount: item.price * item.count,
        vat: 0,
      });
    });

    if (this.state.selectedDeliveryPrice > 0) {
      arr.push({
        label: "Доставка",
        price: this.state.selectedDeliveryPrice,
        quantity: 1,
        amount: this.state.selectedDeliveryPrice,
        vat: 0,
      });
    }

    return arr;
  }

  _convertImages(_img) {
    let arr = [];
    let imgSrc = null;
    if (_img != null) arr = _img.split(",");
    if (arr.length > 0)
      imgSrc =
        getEnvOfStorage()?.uploadsUrl + arr[0].replace(".jpg", "-min.jpg");
    return imgSrc;
  }

  _hasSpecialItemCart(_val) {
    let flag = false;
    this.state.cart.map((item, index) => {
      if (item.special_type == _val) flag = true;
    });
    return flag;
  }

  _getBouquetCount() {
    let val = 0;
    this.state.cart.map((item, index) => {
      if (item.category != 3) {
        val = val + item.count;
      }
    });
    return val;
  }

  resetOrder() {
    this.setState(OrderConstant.DEFAULT_ORDER_STATE, () =>
      this.setState({ isOrderFormHide: false })
    );
    //Обнулит уникальный код заказа orderId
    clearOrderId();
  }

  changeTab(_index) {
    if (this.state.deliveryActive != _index) {
      let price = 0;
      let time = this.state.selectDeliveryExactTime
        ? this.state.selectDeliveryExactTime.format("HH:mm")
        : null;
      if (_index == 0) {
        let time2 = null;
        price = this._getPrice(_index);
        this._checkAvailableDate(new Date()).then(() => {
          if (this.state.deliveries3HoursTimeArray.length > 0) {
            time2 = this.state.deliveries3HoursTimeArray[0].value;
          } else {
            time2 = null;
          }
          this.setState({
            deliveryActive: _index,
            deliveryType: _index,
            selectedDeliveryPrice: price,
            selectedDeliveryDateValue: new Date(),
            selectDeliveryExactDate: new Date(),
            selectedDeliveryTimeValue: time2,
            selectDeliveryTime: time2,
          });
        });
      } else {
        this.setState({
          deliveryActive: _index,
          deliveryType: _index,
          selectedDeliveryPrice: price,
          selectedDeliveryDateValue: new Date(),
          selectDeliveryExactDate: new Date(),
          selectedDeliveryTimeValue: time,
          selectDeliveryTime: time,
        });
      }
    }
  }

  updateProfile() {
    updateUser(
      this.state.user.api_token,
      this.state.user.id,
      this.state.user.email,
      this.state.user.address,
      this.state.userName
    ).then((res) => {
      this.setState({ loader: false });
      if (res.success) {
        if (res?.response?.id) {
          updateClient(res?.response);
          storeData("mfUser", res?.response);
        }
      }
    });
  }

  uploadList(e) {
    this.setState({ imageLoad: true });
    addFile(this.state.selectedFileName, 0).then((res) => {
      this.setState({ imageLoad: false });
      if (res.success) {
        this._changeSettings(
          "image",
          getEnvOfStorage()?.uploadsUrl + res.response
        );
      } else {
        this._alert(res.response);
      }
    });
  }

  async checkActualPrice() {
    let _cart = this.state.cart;
    const list = await Promise.all(
      this.state.cart.map((item) => GetFlowersById(item.id))
    );

    let saveIdArr = [];
    list.map((item) => {
      if (item.response.length > 0) {
        if (item.response[0].id) {
          saveIdArr.push(item.response[0].id);
          _cart.map((itemCart) => {
            if (itemCart.id == item.response[0].id) {
              if (itemCart.price != item.response[0].price) {
                itemCart.price = item.response[0].price;
              }
            }
          });
        }
      }
    });

    var i = _cart.length;
    while (i--) {
      let f = false;
      saveIdArr.map((item) => {
        if (_cart[i].id == item) {
          f = true;
        }
      });
      if (!f) _cart.splice(i, 1);
    }

    storeData("cart", _cart);

    if (_cart.length == 0) {
      storeData("cart", []);
      this.props.history.push("/");
    }

    return await new Promise((resolve) =>
      this.setState(
        {
          cart: _cart,
        },
        resolve
      )
    );
  }

  /**
   * Получить скидку по промокоду
   * @param {any} cart
   * @param {any} user
   * @param {string} promoCode
   */
  sendPromo(cart, user, promoCode) {
    if (user?.api_token && cart?.length > 0 && promoCode?.length > 0) {
      setOrder(cart, generateOrderIdForCart(), 0, user, promoCode)
        .then((data) => {
          let discount = data?.summary?.totalDiscount | 0;
          let maxSumm =
            this._getTotalBasketSales() *
            ((this.state.settings?.settings_max_percent_discount | 15) / 100);

          //console.log(data?.summary?.totalDiscount, maxSumm);
          this.props.setTotalDiscount(discount > maxSumm ? maxSumm : discount);
          this.setState({
            totalDiscount: discount > maxSumm ? maxSumm : discount,
            promoCode: promoCode,
          });
        })
        .catch((err) => console.error(err));
    }
  }

  initializePayWidget(_price, _object) {
    this.setState({ loader: true });
    createOrderOnline(_object).then((res) => {
      this.setState({ loader: false });
      if (res) {
        if (res.code === 0) {
          //Создать/обновить заказ в программе лояльности
          setOrder(
            _object?.cart,
            res?.order,
            _object.discount,
            loadUser(),
            this.props.promoCode
          );

          var receipt = {
            Items: _object.cart_check,
            taxationSystem: 0, //система налогообложения; необязательный, если у вас одна система налогообложения
            amounts: {
              electronic: `${_object.price}.00`, // Сумма оплаты электронными деньгами
              advancePayment: "0.00", // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
              credit: "0.00", // Сумма постоплатой(в кредит) (2 знака после запятой)
              provision: "0.00", // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
            },
          };

          let check = {
            CloudPayments: {
              CustomerReceipt: receipt, //онлайн-чек
            },
          };

          var widget = new window.cp.CloudPayments();
          widget.pay(
            "charge", // или 'charge'
            {
              publicId: "pk_0f321307457b9a107484249288c38", //id из личного кабинета
              description: "Оплата с сайта MateFlower", //назначение
              amount: _price, //сумма
              currency: "RUB", //валюта
              //invoiceId: "Заказ №" + res.order, //номер заказа  (необязательно)
              accountId: this.state.user.email, //идентификатор плательщика (необязательно)
              skin: "classic", //дизайн виджета (необязательно)
              invoiceId: res.order,
              data: check,
              //data: res.order
            },
            {
              onSuccess: (options) => {
                this.resetOrder();
                storeData("cart", null);
                this.props.history.push("/success");
              },
              onFail: function (reason, options) {
                // fail
                //действие при неуспешной оплате
              },
              onComplete: function (paymentResult, options) {},
            }
          );
        } else {
          this._alert(
            "Ошибка создания онлайн заказа. Code " + JSON.stringify(res)
          );
        }
      } else {
        this._alert("Ошибка создания онлайн заказа. " + JSON.stringify(res));
      }
    });
  }

  pay() {
    this.checkActualPrice().then(() => {
      let totalPrice =
        parseFloat(this.state.settings.settings_sale) > 0 ||
        this.props.totalDiscount > 0 ||
        this.state.appliedBonuses > 0
          ? this._getTotalBasket(true) -
            this.props.totalDiscount -
            this.state.appliedBonuses
          : this._getTotalBasket();

      let _userDiscount =
        this.props.totalDiscount > 0
          ? this.props.totalDiscount
          : this.state.appliedBonuses;
      if (
        _userDiscount >
        (this._getTotalBasket(true) *
          (this.state.settings?.settings_max_percent_discount | 15)) /
          100
      )
        _userDiscount =
          (this._getTotalBasket(true) *
            (this.state.settings?.settings_max_percent_discount | 15)) /
          100;

      if (this.state.user) {
        if (this.state.user.id) {
          if (totalPrice > this.state.minimalPrice) {
            if (
              (this.state.deliveryActive == 1 &&
                this.state.selectedDeliveryDateValue != null) ||
              (this.state.deliveryActive != 1 &&
                (this.state.deliveryType == 3 ||
                  (this.state.deliveryType != 3 &&
                    this.state.addressString != "" &&
                    this.state.selectedDeliveryTimeValue != null)))
            ) {
              if (this.state.additionalSettings.iReceiver) {
                this._changeSettings("receiverSettings", {
                  name: this.state.user.name,
                  phone: this.state.user.phone,
                });
              }

              let address =
                this.state.addressString +
                (this.state.addressHome != ""
                  ? ` , ${this.state.addressHome} `
                  : "") +
                (this.state.addressEntrance != ""
                  ? " Подъезд " + this.state.addressEntrance
                  : "") +
                (this.state.addressRoom != ""
                  ? " Квартира " + this.state.addressRoom
                  : "") +
                (this.state.addressFloor != ""
                  ? " Этаж " + this.state.addressFloor
                  : "");

              if (
                this.state.addressesType == "call" ||
                this.state.deliveryActive == 1
              ) {
                address = "";
              }

              let _order = {
                orderMethod: "shopping-cart",
                cart: this._trimInfoFromList(this.state.cart),
                cart_bx: this._trimInfoFromListBX(this.state.cart),
                cart_check: this._trimInfoCheck(this.state.cart),
                additional_settings: this.state.additionalSettings,
                paymentType: this.state.paymentType,
              };
              _order.postCard = this.state.postCardText;
              _order.comment = this.state.commentText;
              _order.address = address;
              _order.distance = this.state.addressDistance;
              _order.date = this.state.selectedDeliveryDateValue;
              _order.time = this.state.selectedDeliveryTimeValue;
              _order.dPrice =
                this.state.deliveryActive == 1
                  ? 0
                  : this.state.selectedDeliveryPrice;
              _order.dateTime = this.state.selectedDeliveryTime;
              _order.deliveryType = this.state.deliveryActive;
              _order.selfDeliveryPlace = this.state.selfDeliveryPlaceValue;
              _order.deliveryInterval = this.state.deliveryType;
              _order.price = totalPrice;
              _order.date = formatDate(_order.date);
              _order.user = this.state.user;
              _order.userName = this.state.userName;
              _order.addressesType = this.state.addressesType;
              _order.addingBonus =
                (this._getTotalBasket() *
                  this.state.settings.settings_percent_to_bonus) /
                100;
              //Присвоить уникальный код заказа
              _order.uOrderId = generateOrderIdForCart();
              _order.promoCode = this.props.promoCode
                ? this.props.promoCode
                : "0";
              _order.discount = _userDiscount;

              this._addAddressToHistory(
                this.state.addressString,
                this.state.addressDistance
              );

              //console.log(_order);

              window.ym(55588969, "reachGoal", "success");

              //TODO
              //убрать после 8
              if (
                formatDateYMD(this.state.selectedDeliveryDateValue) !=
                "2023-03-08"
              ) {
                if (this.state.paymentType == 0) {
                  this.setState({ loader: true });
                  createOrder(_order).then((res) => {
                    this.setState({ loader: false });
                    if (res) {
                      if (res.code == 0) {
                        setOrder(
                          _order?.cart,
                          res?.order,
                          this.props.totalDiscount > 0
                            ? this.props.totalDiscount
                            : this.state.appliedBonuses,
                          loadUser()
                        );
                        storeData("cart", null);
                        this.resetOrder();
                        this.props.history.push("/success");
                      } else {
                        this._alert("Ошибка создания заказа. Code 0.");
                      }
                    } else {
                      this._alert(
                        "Ошибка создания заказа." + JSON.stringify(res)
                      );
                    }
                  });
                } else {
                  this.initializePayWidget(totalPrice, _order);
                }
              } else {
                this._alert("Прием заказов приостановлен");
              }
            } else {
              if (this.state.addressString == "") {
                this._alert("Выберите адрес доставки или введите корректный");
              } else if (this.state.selectedDeliveryTimeValue == null) {
                this._alert("Заполните поле время доставки");
              } else if (this.state.selectedDeliveryDateValue == null) {
                this._alert("Заполните поле дата доставки");
              } else {
                this._alert("Проверьте данные доставки");
              }
            }
          } else {
            this._alert(
              `Минимальная сумма заказа для доставки ${this.state.minimalPrice}р`
            );
          }
        } else {
          this._alert("Время сессии вышло. Повторите вход.");
          logout();
          this.props.history.push("/");
        }
      } else {
        this._alert("Время сессии вышло. Повторите вход.");
        logout();
        this.props.history.push("/");
      }
    });
  }

  _removeItem(_index) {
    let arr = this.state.cart;
    arr.splice(_index, 1);
    storeData("cart", arr);
    this.setState({ cart: arr });

    this.sendPromo(arr, loadUser(), this.state.promoCode);

    if (arr.length == 0) this.props.history.push("/basket");
  }

  _getPrice(_key) {
    let p = 0;
    this.state.deliveries.map((item) => {
      if (item.value == _key) p = item.price;
    });
    return p;
  }

  disabledAllHours() {
    return [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23,
    ];
  }

  disabledHours() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 23];
  }

  disabledHours17() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23];
  }

  disabledHours18() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23];
  }

  disabledHours21() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23];
  }

  disabledHours20() {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 21, 22, 23];
  }

  _alert(_text) {
    this.setState({
      modalAlertActive: true,
      modalAlertActiveText: _text,
    });
  }

  render() {
    let itemsList = this.state.cart.map((item, index) => {
      return (
        <UiHistoryCard
          key={index}
          paymentCard
          image={this._convertImages(item.imgs)}
          title={item.title}
          info={item.description}
          price={item.price * item.count}
          count={item.count}
          url={item.title_url}
          category={item.category}
          giftBox={item.giftbox}
          special={item.special_type}
          onDelete={() => {
            this._removeItem(index);
          }}
          onChangeGiftBox={(val) =>
            this._change("giftbox", index, val == 1 ? true : false)
          }
        />
      );
    });

    return (
      <div ref={this.divPage} id="page">
        <Helmet>
          <title>Оформление заказа MATE flowers</title>
          <meta name="description" content="Оформление заказа MATE flowers" />
          <meta
            name="keywords"
            content="Доставка букетов Москва, купить цветы Москва"
          />
        </Helmet>
        <div id="wrapper" className="page-wrapper">
          {/* basket header */}
          <section className="section basket-header">
            <UiHeader
              callBack={(value) => {}}
              page="basket"
              scrollHeader={this.state.scrollHeader}
              basketNum={this.state.basketNum}
              isLogin={this.state.user}
              loginClick={() => this.setState({ modalLoginActive: true })}
              profileClick={() => this.props.history.push("/profile")}
            />
          </section>

          {/* order content */}
          {!this.state.isOrderFormHide ? (
            <section className="section order-content">
              <div className="wrapper">
                <div className="order-wrap">
                  {/* order info scroll */}
                  <div className="order-info">
                    <h3>Оформление заказа</h3>

                    {/* order list fixed */}
                    <div className="order-list-wrap static-list">
                      <h3>Состав заказа:</h3>
                      <div className="order-list">
                        {itemsList}
                        <UiHistoryCard title="Подарочный набор" price="0" />
                      </div>
                      <UiBtnLight btnText="+ Добавить товары" href="/" />
                      {/*Стоимость доставки*/}
                      <UiDeliverPriceCaption
                        price={this.state.selectedDeliveryPrice}
                      />
                      {/*Доступные бонусы для списания (считается по составу товаров)*/}
                      <UiAvailableBonuses
                        disabled={this.props.totalDiscount > 0}
                        totalDiscount={this.props.totalDiscount}
                        maxDiscount={this._getTotalBasketSales()}
                        settings={this.state.settings}
                        summ={this._getTotalBasket(true)}
                        onBonusChange={(val) => this.onBonusChange(val)}
                        onSendPromoCode={(val) =>
                          this.sendPromo(retrieveData(CART), loadUser(), val)
                        }
                      />
                      <div className="order-price-wrap">
                        <h4>Стоимость заказа:</h4>

                        {parseFloat(this.state.settings.settings_sale) > 0 ||
                        this.props.totalDiscount > 0 ||
                        this.state.appliedBonuses > 0 ? (
                          <p className="order-price">
                            <span>{this._getTotalBasket(true)}&nbsp;₽</span>{" "}
                            {this._getTotalBasket(true) -
                              this.props.totalDiscount -
                              this.state.appliedBonuses}
                            &nbsp;₽
                          </p>
                        ) : (
                          <p className="order-price">
                            {this._getTotalBasket()}&nbsp;₽
                          </p>
                        )}

                        {parseFloat(this.state.settings.settings_sale) > 0 ? (
                          <p>
                            <b>
                              Скидка на ваш заказ составила:{" "}
                              {(this._getTotalBasket() *
                                this.state.settings.settings_sale) /
                                100}
                              &nbsp;₽
                            </b>
                          </p>
                        ) : null}

                        {/*<p>
                          <span>
                            На ваш счет будет начислено{" "}
                            {(this._getTotalBasket() *
                              this.state.settings.settings_percent_to_bonus) /
                              100}{" "}
                            бонусов
                          </span>
                        </p>*/}
                      </div>
                    </div>
                    <div className="order-info-card-wrap">
                      <UiTextInput
                        label="Как Вас зовут?"
                        value={this.state.userName}
                        callBack={(val) => this.setState({ userName: val })}
                        onBlur={() => this.updateProfile()}
                      />
                    </div>

                    <div className="order-info-card-wrap">
                      <div className="order-delivery-wrap">
                        <div className="delivery-tabs">
                          <button
                            className={
                              "delivery-tab" +
                              (this.state.deliveryActive == 0 ? " active" : "")
                            }
                            onClick={() => this.changeTab(0)}
                          >
                            <h3>Доставка</h3>
                          </button>
                         
                          <button
                            className={
                              "delivery-tab" +
                              (this.state.deliveryActive != 1 ? "" : " active")
                            }
                            onClick={() => this.changeTab(1)}
                          >
                            <h3>Самовывоз</h3>
                          </button>
                        
                        </div>


                        {this.state.deliveryActive == 0 ? (
                          <div className="delivery-tab-wrap">
                            <h4>Куда доставить?</h4>
                            <div className="order-delivery-checklist">
                              <UiCheckList
                                callBack={(value) =>
                                  this._selectHistoryAddress(value)
                                }
                                checkList={this.state.addressesHistory}
                                activeCheck={this.state.adressTypeValue}
                                radio
                              />
                            </div>
                            {this.state.addressesType == "new" ? (
                              <div className="order-delivery-new">
                                <UiTextInputSV
                                  placeholder="Улица"
                                  overflow
                                  value={this.state.addressString}
                                  callBack={(res) => {
                                    console.log(
                                      "res",
                                      this.state.addressString,
                                      res
                                    );
                                    this._changeAddress(
                                      res,
                                      this.state.deliverySettings
                                    );
                                  }}
                                />

                                <div id="ymap"></div>

                                {/*

                                <UiTextInput
                                  id={"suggest-input"}
                                  placeholder="Улица"
                                  overflow
                                  value={this.state.addressString}
                                  callBack={(res) => {
                                    this.setState({addressString: res});
                                  }}
                                />
                                */}

                                <div className="order-delivery-new-wrap">
                                  <UiTextInput
                                    placeholder="Дом"
                                    onBlur={(val) => {
                                      this.setState({ addressHome: val });
                                      let line =
                                        this.state.addressString.split(",");
                                      if (!isNaN(line[line.length - 1]))
                                        line.splice(line.length - 1, 1);
                                      line.push(val);
                                      //.log("res", this.state.addressString, val, line)
                                      this._changeAddress(
                                        {
                                          address: line.join(","),
                                          distance: this.state.addressDistance,
                                        },
                                        this.state.deliverySettings
                                      );
                                    }}
                                  />

                                  <UiTextInput
                                    placeholder="Подъезд"
                                    callBack={(val) =>
                                      this.setState({ addressEntrance: val })
                                    }
                                  />
                                  <UiTextInput
                                    placeholder="Квартира"
                                    callBack={(val) =>
                                      this.setState({ addressRoom: val })
                                    }
                                  />
                                  <UiTextInput
                                    placeholder="Этаж"
                                    callBack={(val) =>
                                      this.setState({ addressFloor: val })
                                    }
                                  />

                                  <UiMapAddress
                                    address={this.state.addressString}
                                    onChangeAddress={(line) => {
                                      this._changeAddress(
                                        {
                                          address: line,
                                          distance: this.state.addressDistance,
                                        },
                                        this.state.deliverySettings
                                      );
                                    }}
                                    onChange={(t) => {
                                      //console.log(t);
                                      /*
                                      this._changeAddress(
                                        {
                                          address: this.state.addressString,
                                          distance: this.state.addressDistance,
                                        }, 
                                        this.state.deliverySettings
                                      );
                                      */
                                    }}
                                  />
                                </div>
                              </div>
                            ) : null}
                            {this.state.isShowIntervalForm ? (
                              <h4>Когда доставить?</h4>
                            ) : null}
                            {this.state.isShowIntervalForm ? (
                              <div className="delivery-type-wrap">
                                <UiCheckList
                                  activeCheck={this.state.deliveryType}
                                  callBack={(value) => {
                                    this.setState(
                                      {
                                        deliveryType: value,
                                        selectDeliveryTime: null,
                                      },
                                      () => {
                                        if (
                                          value == 0 &&
                                          this.state.deliveries3HoursTimeArray
                                            .length > 0
                                        )
                                          this.setState({
                                            selectDeliveryTime:
                                              this.state
                                                .deliveries3HoursTimeArray[0]
                                                .value,
                                          });
                                        if (
                                          value == 1 &&
                                          this.state.deliveries1HoursTimeArray
                                            .length > 0
                                        )
                                          this.setState({
                                            selectDeliveryTime:
                                              this.state
                                                .deliveries1HoursTimeArray[0]
                                                .value,
                                          });
                                        if (
                                          value == 4 &&
                                          this.state.deliveriesTimeArray
                                            .length > 0
                                        )
                                          this.setState({
                                            selectDeliveryTime:
                                              this.state.deliveriesTimeArray[0]
                                                .option,
                                            selectedDeliveryPrice:
                                              this.state.deliveriesTimeArray[0]
                                                .value,
                                          });
                                        this._setDelivery();
                                      }
                                    );
                                  }}
                                  checkList={this._excludeSpecialInterval(
                                    this.state.deliveries
                                  )}
                                />

                                {this.state.deliveryType == 4 ? (
                                  <div className="delivery-type-info">
                                    <div className="delivery-date-time-select">
                                      <DatePicker
                                        locale="ru"
                                        dateFormat="dd.MM.yyyy"
                                        clearIcon={null}
                                        selected={
                                          this.state.selectDeliveryExactDate
                                        }
                                        excludeDates={this.state.excludeDates}
                                        minDate={new Date()}
                                        onChange={(e) =>
                                          this.setState(
                                            {
                                              selectDeliveryExactDate: e,
                                            },
                                            () => {
                                              this._loadInterval(e).then(() => {
                                                this._checkAvailableDate(
                                                  this.state
                                                    .selectDeliveryExactDate
                                                ).then(() => {
                                                  if (
                                                    this.state
                                                      .deliveriesTimeArray
                                                      .length > 0
                                                  ) {
                                                    this.setState(
                                                      {
                                                        selectDeliveryTime:
                                                          this.state
                                                            .deliveriesTimeArray[0]
                                                            .option,
                                                        selectedDeliveryTimeValue:
                                                          this.state
                                                            .deliveriesTimeArray[0]
                                                            .option,
                                                      },
                                                      () => {
                                                        this._setDelivery();
                                                      }
                                                    );
                                                  } else {
                                                    this._setDelivery();
                                                  }
                                                });
                                              });
                                            }
                                          )
                                        }
                                      />
                                      <UiSelect
                                        callBack={(value) => {
                                          this.setState(
                                            {
                                              selectedDeliveryPrice:
                                                value.value,
                                              selectDeliveryTime: value.option,
                                            },
                                            () => this._setDelivery()
                                          );
                                        }}
                                        showPrice
                                        selectItems={
                                          !this._disableEveningTimes(null)
                                            ? this.state.deliveriesTimeArray
                                            : []
                                        }
                                        selectedText={
                                          this.state.deliveriesTimeArray
                                            .length == 0 ||
                                          this._disableEveningTimes(null)
                                            ? "Интервалы не доступны"
                                            : this.state.selectDeliveryTime
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : this.state.deliveryType == 3 ? (
                                  <div className="delivery-type-info">
                                    <div className="delivery-date-time-select">
                                      <DatePicker
                                        locale="ru"
                                        dateFormat="dd.MM.yyyy"
                                        clearIcon={null}
                                        selected={
                                          this.state.selectDeliveryExactDate
                                        }
                                        excludeDates={this.state.excludeDates}
                                        minDate={new Date()}
                                        onChange={(e) =>
                                          this.setState(
                                            {
                                              selectDeliveryExactDate: e,
                                            },
                                            () => {
                                              this._loadInterval(e).then(() => {
                                                this._setDelivery();
                                                this._checkAvailableDate(
                                                  this.state
                                                    .selectDeliveryExactDate
                                                ).then(() => {});
                                              });
                                            }
                                          )
                                        }
                                      />
                                    </div>
                                    <p>
                                      Мы уточним адрес и время доставки у
                                      получателя. Если адрес окажется за МКАД
                                      или же трехчасовой интервал не подойдет
                                      получателю, то наш менеджер свяжется с
                                      Вами для доплаты за доставку
                                    </p>
                                  </div>
                                ) : this.state.deliveryType == 2 ? (
                                  <div className="delivery-type-info">
                                    <div className="delivery-date-time-select">
                                      <DatePicker
                                        locale="ru"
                                        dateFormat="dd.MM.yyyy"
                                        clearIcon={null}
                                        selected={
                                          this.state.selectDeliveryExactDate
                                        }
                                        excludeDates={this.state.excludeDates}
                                        minDate={new Date()}
                                        onChange={(e) =>
                                          this._loadInterval(e).then(() => {
                                            this.setState(
                                              {
                                                selectDeliveryExactDate: e,
                                              },
                                              () => this._setDelivery()
                                            );
                                          })
                                        }
                                      />
                                      {!this._disableEveningTimes() ? (
                                        <TimePicker
                                          style={{ width: 100 }}
                                          hourStep={1}
                                          minuteStep={30}
                                          showSecond={false}
                                          disabledHours={this.disabledHours}
                                          value={
                                            this.state.selectDeliveryExactTime
                                          }
                                          onChange={(value) => {
                                            this.setState(
                                              {
                                                selectDeliveryExactTime:
                                                  value && value,
                                              },
                                              () => this._setDelivery()
                                            );
                                          }}
                                        />
                                      ) : (
                                        <UiSelect
                                          callBack={(value) => {}}
                                          selectItems={[]}
                                          selectedText={"Интервалы не доступны"}
                                        />
                                      )}
                                    </div>
                                  </div>
                                ) : this.state.deliveryType == 1 ? (
                                  <div className="delivery-type-info">
                                    <div className="delivery-date-time-select">
                                      <DatePicker
                                        locale="ru"
                                        dateFormat="dd.MM.yyyy"
                                        clearIcon={null}
                                        selected={
                                          this.state.selectDeliveryExactDate
                                        }
                                        excludeDates={this.state.excludeDates}
                                        minDate={new Date()}
                                        onChange={(e) =>
                                          this.setState(
                                            {
                                              selectDeliveryExactDate: e,
                                            },
                                            () => {
                                              this._loadInterval(e).then(() => {
                                                this._checkAvailableDate(
                                                  this.state
                                                    .selectDeliveryExactDate
                                                ).then(() => {
                                                  if (
                                                    this.state
                                                      .deliveries1HoursTimeArray
                                                      .length > 0
                                                  ) {
                                                    this.setState(
                                                      {
                                                        selectDeliveryTime:
                                                          this.state
                                                            .deliveries1HoursTimeArray[0]
                                                            .value,
                                                        selectDeliveryTimeValue:
                                                          this.state
                                                            .deliveries1HoursTimeArray[0]
                                                            .value,
                                                      },
                                                      () => {
                                                        this._setDelivery();
                                                      }
                                                    );
                                                  } else {
                                                    this._setDelivery();
                                                  }
                                                });
                                              });
                                            }
                                          )
                                        }
                                      />

                                      <UiSelect
                                        callBack={(value) => {
                                          if (
                                            !this._disableEveningTimes(
                                              value.option
                                            )
                                          ) {
                                            this.setState(
                                              {
                                                selectDeliveryTimeValue:
                                                  value.value,
                                                selectDeliveryTime:
                                                  value.option,
                                              },
                                              () => this._setDelivery()
                                            );
                                          } else {
                                            this.setState({
                                              selectedDeliveryTimeValue: null,
                                            });
                                            this._alert(
                                              "К сожалению сегодня мы не можем доставить в это время. Выберите другой день."
                                            );
                                          }
                                        }}
                                        selectItems={
                                          this.state.deliveries1HoursTimeArray
                                        }
                                        selectedText={
                                          this.state.deliveries1HoursTimeArray
                                            .length == 0
                                            ? "Интервалы не доступны"
                                            : this.state.selectDeliveryTime
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : this.state.deliveryType == 0 ? (
                                  <div className="delivery-type-info">
                                    <div className="delivery-date-time-select">
                                      <DatePicker
                                        locale="ru"
                                        dateFormat="dd.MM.yyyy"
                                        clearIcon={null}
                                        selected={
                                          this.state.selectDeliveryExactDate
                                        }
                                        excludeDates={this.state.excludeDates}
                                        minDate={new Date()}
                                        onChange={(e) =>
                                          this._loadInterval(e).then(() => {
                                            this.setState(
                                              {
                                                selectDeliveryExactDate: e,
                                              },
                                              () => {
                                                this._checkAvailableDate(
                                                  this.state
                                                    .selectDeliveryExactDate
                                                ).then(() => {
                                                  if (
                                                    this.state
                                                      .deliveries3HoursTimeArray
                                                      .length > 0
                                                  ) {
                                                    this.setState(
                                                      {
                                                        selectDeliveryTime:
                                                          this.state
                                                            .deliveries3HoursTimeArray[0]
                                                            .value,
                                                        selectDeliveryTimeValue:
                                                          this.state
                                                            .deliveries3HoursTimeArray[0]
                                                            .value,
                                                      },
                                                      () => {
                                                        this._setDelivery();
                                                      }
                                                    );
                                                  } else {
                                                    this._setDelivery();
                                                  }
                                                });
                                              }
                                            );
                                          })
                                        }
                                      />

                                      <UiSelect
                                        callBack={(value) => {
                                          if (
                                            !this._disableEveningTimes(
                                              value.option
                                            )
                                          ) {
                                            this.setState(
                                              {
                                                selectDeliveryTimeValue:
                                                  value.value,
                                                selectDeliveryTime:
                                                  value.option,
                                              },
                                              () => this._setDelivery()
                                            );
                                          } else {
                                            this.setState({
                                              selectedDeliveryTimeValue: null,
                                            });
                                            this._alert(
                                              "К сожалению сегодня мы не можем доставить в это время. Выберите другой день."
                                            );
                                          }
                                        }}
                                        selectItems={
                                          this.state.deliveries3HoursTimeArray
                                        }
                                        selectedText={
                                          this.state.deliveries3HoursTimeArray
                                            .length == 0
                                            ? "Интервалы не доступны"
                                            : this.state.selectDeliveryTime
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : null}
                                <p>
                                  Указаны доступные интервалы доставки для
                                  Вашего адреса
                                </p>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div className="delivery-tab-wrap">
                            <div className="delivery-type-info">
                              <div className="delivery-date-time-select">
                                <DatePicker
                                  locale="ru"
                                  dateFormat="dd.MM.yyyy"
                                  clearIcon={null}
                                  selected={this.state.selectDeliveryExactDate}
                                  excludeDates={this.state.excludeDates}
                                  minDate={new Date()}
                                  onChange={(e) =>
                                    this._loadInterval(e).then(() => {
                                      this.setState(
                                        {
                                          selectDeliveryExactDate: e,
                                        },
                                        () => {
                                          this._setDelivery();
                                          this._checkAvailableDate(
                                            this.state.selectDeliveryExactDate
                                          );
                                        }
                                      );
                                    })
                                  }
                                />

                                <TimePicker
                                  style={{ width: 100 }}
                                  hourStep={1}
                                  minuteStep={30}
                                  showSecond={false}
                                  disabledHours={this.disabledHours20}
                                  value={this.state.selectDeliveryExactTime}
                                  onChange={(value) => {
                                    if (
                                      value &&
                                      value.format("HH:mm") != "20:30"
                                    ) {
                                      this.setState(
                                        {
                                          selectDeliveryExactTime:
                                            value && value,
                                        },
                                        () => this._setDelivery()
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>

                            <div className="order-delivery-checklist">
                              <UiCheckList
                                callBack={(value) => {
                                  this.setState({
                                    selfDeliveryPlaceValue: value,
                                  });
                                }}
                                checkList={this.state.selfDeliveryPlaces}
                                activeCheck={this.state.selfDeliveryPlaceValue}
                                radio
                              />
                            </div>

                            <p>
                              Вы можете самостоятельно забрать заказ в одной из
                              наших мастерских. <br />{" "}
                            </p>
                          </div>
                        )}

                        {/* Добавление получателя */}
                        <div className="order-recipient">
                          <h4>Кто получит заказ?</h4>
                          <UiCheckbox
                            value={this.state.additionalSettings.iReceiver}
                            callBack={(value) =>
                              this._changeSettings("iReceiver", value)
                            }
                            labelDisabled="Я получатель"
                            labelEnabled="Получатель не я"
                          />
                          {!this.state.additionalSettings.iReceiver ? (
                            <div className="recipient-wrap">
                              <h4>Имя получателя:</h4>
                              <UiTextInput
                                placeholder=""
                                callBack={(val) =>
                                  this._changeSettings("receiverSettings", {
                                    name: val,
                                    phone:
                                      this.state.additionalSettings
                                        .receiverSettings.phone,
                                  })
                                }
                              />
                              <h4>Номер телефона получателя:</h4>
                              <UiTextInputMasked
                                placeholder=""
                                callBack={(val) =>
                                  this._changeSettings("receiverSettings", {
                                    name: this.state.additionalSettings
                                      .receiverSettings.name,
                                    phone: val,
                                  })
                                }
                              />
                              <p className="msg-text">
                                <span>Не переживайте, это будет сюрприз</span>{" "}
                                <span className="msg-text-icon">
                                  <Icon icon={alertCircle} />
                                </span>
                              </p>
                            </div>
                          ) : null}

                          <UiCheckbox
                            value={
                              this.state.additionalSettings.callBeforeDelivery
                            }
                            callBack={(val) =>
                              this._changeSettings("callBeforeDelivery", !val)
                            }
                            labelEnabled="Не звонить получателю перед доставкой"
                            labelDisabled="Звонок получателю перед доставкой"
                          />
                          <h4>Открытка получателю (бесплатно):</h4>
                          <UiTextArea
                            placeholder="Напишите текст поздравления"
                            callBack={(res) =>
                              this.setState({ postCardText: res })
                            }
                          />
                          <h4>Комментарий к заказу (пожелание):</h4>
                          <UiTextArea
                            placeholder="Не обязательное поле"
                            callBack={(res) =>
                              this.setState({ commentText: res })
                            }
                          />

                          {this._getBouquetCount() > 0 ? (
                            <h4>Кол-во коробок для букетов:</h4>
                          ) : null}
                          {this._getBouquetCount() > 0 ? (
                            <div className="order-boxes-info">
                              <button
                                className="btn"
                                onClick={() => {
                                  let val =
                                    this.state.additionalSettings.boxCount;
                                  if (val - 1 >= 0)
                                    this._changeSettings("boxCount", val - 1);
                                }}
                              >
                                <Icon icon={minusIcon} />
                              </button>
                              <span>
                                {this.state.additionalSettings.boxCount}
                              </span>
                              <button
                                className="btn"
                                onClick={() => {
                                  let val =
                                    this.state.additionalSettings.boxCount;
                                  if (this._getBouquetCount() >= val + 1)
                                    this._changeSettings("boxCount", val + 1);
                                }}
                              >
                                <Icon icon={plusIcon} />
                              </button>
                            </div>
                          ) : null}

                          {this._hasSpecialItemCart("get_photo") ? (
                            <h4>Загрузите фото:</h4>
                          ) : (
                            ""
                          )}
                          {this._hasSpecialItemCart("get_photo") ? (
                            <label>
                              {this.state.additionalSettings.image ? (
                                <img
                                  className="add-photo"
                                  src={this.state.additionalSettings.image}
                                />
                              ) : (
                                <img
                                  className="add-photo"
                                  src={require("../../assets/images/catalog/photos.svg")}
                                />
                              )}
                              <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  this.setState(
                                    { selectedFileName: e.target.files[0] },
                                    () => {
                                      this.uploadList();
                                    }
                                  );
                                }}
                              />
                            </label>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="order-info-card-wrap">
                      <h3>Способ оплаты:</h3>
                      {this._getTotalBasket() < 6000 ||
                      this.state.deliveryActive == 1 ? (
                        <div className="payment-type-wrap">
                          <button
                            className={
                              this.state.paymentType == 0 ? "active" : ""
                            }
                            onClick={() => this.setState({ paymentType: 0 })}
                          >
                            <img
                              src={require("../../assets/images/basket/cash.svg")}
                            />
                            {this.state.deliveryActive == 1 ? (
                              <span>
                                При
                                <br />
                                получении
                              </span>
                            ) : (
                              <span>
                                Наличными
                                <br />
                                курьеру
                              </span>
                            )}
                          </button>

                          <button
                            className={
                              this.state.paymentType == 1 ? "active" : ""
                            }
                            onClick={() => this.setState({ paymentType: 1 })}
                          >
                            <img
                              src={require("../../assets/images/basket/card.svg")}
                            />
                            <span>
                              Оплата
                              <br />
                              онлайн
                            </span>
                          </button>
                        </div>
                      ) : (
                        <div className="payment-type-wrap">
                          <button
                            className={
                              this.state.paymentType == 1 ? "active" : ""
                            }
                            onClick={() => this.setState({ paymentType: 1 })}
                          >
                            <img
                              src={require("../../assets/images/basket/card.svg")}
                            />
                            <span>
                              Оплата
                              <br />
                              онлайн
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="order-next-wrap"> 
                      <UiBtnPinkOutline
                        btnBig
                        btnText={`${
                          this.state.paymentType == "0"
                            ? "Заказать за"
                            : "Оплатить"
                        } ${
                          parseFloat(this.state.settings.settings_sale) > 0 ||
                          this.props.totalDiscount > 0 ||
                          this.state.appliedBonuses > 0
                            ? this._getTotalBasket(true) -
                              this.props.totalDiscount -
                              this.state.appliedBonuses
                            : this._getTotalBasket()
                        }₽`}
                        onClick={() => this.pay()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
          {/* basket footer */}
          <section className="section basket-footer">
            <UiFooter />
          </section>
        </div>

        {/* order list fixed */}
        <div id="basket-list" className="order-list-wrap fixed-list">
          <h3>Состав заказа:</h3>
          <div className="order-list">
            {itemsList}
            <UiHistoryCard
              paymentCard
              type="free"
              image={require("../../assets/images/demo/gift-1.png")}
              title="Подарочный набор"
              price={0}
            />
          </div>
          <UiBtnLight btnText="+ Добавить товары" href="/" />
          <UiDeliverPriceCaption price={this.state.selectedDeliveryPrice} />
          {/*Доступные бонусы для списания (считается по составу товаров)*/}
          <UiAvailableBonuses
            disabled={this.props.totalDiscount > 0}
            totalDiscount={this.props.totalDiscount}
            maxDiscount={this._getTotalBasketSales()}
            settings={this.state.settings}
            summ={this._getTotalBasket(true)}
            onBonusChange={(val) => this.onBonusChange(val)}
            onSendPromoCode={(val) =>
              this.sendPromo(retrieveData(CART), loadUser(), val)
            }
          />
          <div className="order-price-wrap">
            <h4>Стоимость заказа:</h4>
            {parseFloat(this.state.settings.settings_sale) > 0 ||
            this.props.totalDiscount > 0 ||
            this.state.appliedBonuses > 0 ? (
              <p className="order-price">
                <span>{this._getTotalBasket(true)}&nbsp;₽</span>{" "}
                {this._getTotalBasket(true) -
                  this.props.totalDiscount -
                  this.state.appliedBonuses}
                &nbsp;₽
              </p>
            ) : (
              <p className="order-price">{this._getTotalBasket()}&nbsp;₽</p>
            )}

            {parseFloat(this.state.settings.settings_sale) > 0 ? (
              <p>
                <b>
                  Скидка на ваш заказ составила:{" "}
                  {(this._getTotalBasket() *
                    this.state.settings.settings_sale) /
                    100}
                  &nbsp;₽
                </b>
              </p>
            ) : null}

            <p>
              {/*<span>
                На ваш счет будет начислено{" "}
                {(this._getTotalBasket() *
                  this.state.settings.settings_percent_to_bonus) /
                  100}{" "}
                бонусов
              </span>*/}
            </p>
          </div>
        </div>

        <UiBtnTop showTopBtn={this.state.showTopBtn} />

        <UiModalLogin
          modalOpen={this.state.modalLoginActive}
          modalClose={() => this.setState({ modalLoginActive: false })}
          callBack={() => {
            this.setState({ modalLoginActive: false });
            this.setState({ modalPaymentActive: true });
          }}
        />

        <UiModalAlert
          text={this.state.modalAlertActiveText}
          modalOpen={this.state.modalAlertActive}
          modalClose={() => this.setState({ modalAlertActive: false })}
          callBack={() => {
            this.setState({ modalAlertActive: false });
          }}
        />

        <UiModalRules
          modalOpen={this.state.modalRulesActive}
          modalClose={() => this.setState({ modalRulesActive: false })}
        />

        <UiLoader modalOpen={this.state.loader} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setTotalBonuses: (value) => dispatch(setTotalBonuses(value)),
    setAppliedBonuses: (value) => dispatch(setAppliedBonuses(value)),
    setTotalDiscount: (value) => dispatch(setTotalDiscount(value)),
  };
};

function mapStateToProps(state) {
  const { loyalty } = state;
  return {
    totalBonuses: loyalty.totalBonuses,
    appliedBonuses: loyalty.appliedBonuses,
    promoCode: loyalty.promoCode,
    totalDiscount: loyalty.totalDiscount,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderPage);
